import {
  ARTICLESS0E0,
  ARTICLESS0PDF,
  ARTICLESS1E0,
  ARTICLESS1PDF,
  ARTICLESS2E0,
  ARTICLESS3E0,
  ARTICLESS3PDF,
} from "../../Constanst/constanst.Const";

//   {
//     id: Number,
//     name: 'String',
//     image: imgSrc ,
//     file: pdfSrc,
//     description: 'String',
//     otherData: 'String',
//   },
export const ARTICLE_ITEMS = [
  {
    id: 0,
    name: "افزایش زمان ماندگاری ماهی قزل آلا صید شده با استفاده از گاز اوزون",
    image: ARTICLESS0E0,
    file: ARTICLESS0PDF,
    description:
      "در این پژوهش با استفاده از خواص ضدعفونی کننده ازن ماندگاری ماهی قزل آلا صید شده را افزایش داده اند. بدین منظور، نمونه های ماهی قزل آلا به مدت دو ساعت در محیط آبی در معرض ازن قرار گرفتند و رشد میکروبی و خواص بیوشیمیایی آنها در طول زمان اندازه گیری شد. رشد میکروبی ماهی های تیمار شده با ازن به طور قابل توجهی کندتر از نمونه شاهد بود و در نتیجه تعداد باکتری‌ها کمتر شد. طبق آزمایشات بیوشیمیایی؛ تیمار ازن هیچ اثر منفی بر چربی، پروتئین و رطوبت ماهی نداشت. اندازه‌گیری‌ها نشان داد که استفاده از گاز ازن، ماندگاری ماهی قزل‌آلا را از 4 روز به 6 روز افزایش داد. با توجه به تجزیه و تحلیل حسی، هیچ تغییری در رنگ یا طعم ماهی قزل آلای تیمار شده با ازن مشاهده نشد.",
    otherData: null,
  },
  {
    id: 1,
    name: "تصفیه و ضدعفونی کردن پساب های بیمارستانی",
    image: ARTICLESS1E0,
    file: ARTICLESS1PDF,
    description:
      "در ژاپن پژوهشی جهت استریل و کاهش بار میکروبی (بی خطر سازی) پساب های بیمارستانی با استفاده از سیستم های اوزن دهی صورت گرفته است که نتایج نشان داد تصفیه با ازن غیرفعال‌ سازی مؤثر باکتری‌های عمومی روده، از جمله باکتری‌ها، پرووتلا، اشریشیا کلی، کلبسیلا، مولکول‌های DNA و ARGs و همچنین ضد میکروبی‌ها را ممکن می‌سازد. میزان حذف آزیترومایسین و داکسی سایکلین بلافاصله پس از تصفیه بیش از 99 درصد بود و میزان حذف لووفلوکساسین و وانکومایسین برای تقریباً یک ماه بین 90 تا 97 درصد باقی ماند. علاوه بر این موارد کلاریترومایسین با سهولت بیشتری نسبت به سایر داروهای ضد میکروبی (91-81%) حذف شد.",
    otherData: null,
  },
  {
    id: 2,
    name: "حذف باکتری های مقاوم به کلر در آب آشامیدنی با استفاده از فناوری های مبتنی بر گاز ازون",
    image: ARTICLESS2E0,
    file: "https://doi.org/10.1016/j.jwpe.2023.103772",
    description:
      "کلر، رایج ترین روش ضد عفونی، اغلب در فرآیند ضد عفونی آب آشامیدنی استفاده می شود. با این حال، برخی از موجودات زنده می توانند حتی در شرایط غلظت بالای کلر نیز زنده بمانند. که به نوبه خود خطر قابل توجهی برای ایمنی آب آشامیدنی دارد. این پژوهش به بررسی منشأ باکتری‌های مقاوم به کلر در شبکه‌های آب آشامیدنی، گونه‌های باکتری‌های مقاوم به کلر یافت شده در سال‌های اخیر و روش‌های مرسوم شناسایی باکتری‌های مقاوم به کلر می‌پردازد، تکنیک‌های غیرفعال‌سازی فعلی برای باکتری‌های مقاوم به کلر نیز به صورت خلاصه شرح داده شده است.",
    otherData: null,
  },
  {
    id: 3,
    name: "اثر ازن زنی جهت بهبود ایمنی میکروبیولوژیکی برگ جعفری تازه خرد شده (Petroselinum crispum)",
    image: ARTICLESS3E0,
    file: ARTICLESS3PDF,
    description:
      "هدف از این مطالعه ارزیابی اثربخشی آب ازن دار در بهبود ایمنی میکروبی برگ های تازه بریده شده جعفری با تمرکز ویژه بر باکتری‌ها و قارچ های مزوفیل و سایکروتروف بود. نتایج این مطالعه نشان می‌دهد که آلودگی‌زدایی برگ‌های جعفری با آب ازن دار حاوی 1/503 میلی‌گرم در لیتر در یک فرآیند بسته 5 دقیقه‌ای می‌تواند به طور موثری کیفیت میکروبیولوژیکی برگ‌های جعفری تازه بریده شده را تضمین کند. می توان نتیجه گرفت که تیمارهای ازن در فرم آبی به نظر می رسد نتایج کمی و کیفی امیدوارکننده ای را برای رفع آلودگی این ماده گیاهی تازه برش داده شده ارائه دهد.",
    otherData: null,
  },
];

//    [
//   {
//     id: 0,
//     name: "mamad",
//     image: LOGO,
//     file: cvEnMalakdar,
//     description:
//       "اگه از اینجا میخوای بری باید چمدونت رو ببندی و تا وقتی از اینجا نری نمیتونی از اینجا رفته باشی. اینو تمام کسایی که از اینجا رفتن و تمام کسایی که هنوز نرفتن هم تایید و تکذیب میکنن چون فقط کسایی رفته باشن رفتن و تمام کسایی ک موندن یقیناً هستن.",
//     otherData: null,
//   },
// ];
