import {
  EMPLOYMENTOFWOMENIMG,
  EMPLOYMENTOFWOMENMP4,
  NEWSINTERVIEWIMG,
  NEWSINTERVIEWMP4,
  VISITINGORGANIZATIONSIMG,
  VISITINGORGANIZATIONSMP4,
} from "../../Constanst/constanst.Const";

// {
//   id: 0,
//   title: "",
//   image: LOGO,
//   carouselImage: [{ original: LOGO }, { original: LOGO }],
//   video: NEWSINTERVIEWMP4 ,
//   body:  <span>text<br/>nextline</span>,
// },
export const LAST_EVENT = [
  {
    id: 0,
    title: "بازدید سازمان‌ها",
    image: VISITINGORGANIZATIONSIMG,
    carouselImage: [{ original: VISITINGORGANIZATIONSIMG }],
    video: VISITINGORGANIZATIONSMP4,
    body: (
      <span>
        با تاسیس کارگاه خانگی و دریافت جواز تاسیس و تولید از اداره صنعت معدن و
        تجارت شهرستان بابل، جهت اشتغال بانوان در استان مازندران / شهرستان بابل،
        چند تن از رئسای سازمان‌ها و ادارات از جمله محیط زیست، اداره آب و فاضلاب،
        نماینده بازرسی اداره صنعت، معدن و تجارت به همراه فرماندار ویژه شهرستان
        بابل و بخش دار محترم شهرستان جهت حمایت و بازدید از کارگاه و هم چنین رفع
        نیازها و موانع موجود در امر اداره و ادامه فعالیت در تاریخ 1400/10/06 در
        دفتر مدیریت کارگاه حضور پیدا کردند.
        <br />
        نتیجه این بازدید رشد بیشتر واحد تولید و معرفی این واحد به ادارات بوده که
        به نوبه خود گامی بلند در جهت افزایش تولید و بومی سازی علوم حتی در واحد
        های کوچک گشت.
      </span>
    ),
  },
  {
    id: 1,
    title: "اشتغال بانوان",
    image: EMPLOYMENTOFWOMENIMG,
    carouselImage: [{ original: EMPLOYMENTOFWOMENIMG }],
    video: EMPLOYMENTOFWOMENMP4,
    body: (
      <span>
        یکی از اهداف صنایع بیت الکترونیک افزایش ظرفیت اشتغال و هم چنین کاهش
        اتلاف انرژی به واسطه دورکاری نیروی ماهر در منازل می باشد.
        <br />
        از این رو در سال 1400 یک کارگاه کوچک در روستای ترک محله جهت اشتغال
        بانوان تاسیس نمود و مونتاژ برد های الکترونیکی را به صورت دورکاری در
        کارگاه خانگی خارج از کارخانه در دستورکار خود قرار داد.
        <br />
        این امر اولا موجب اشتغال تعدادی از نیروی کار جوان ماهر در روستا گشت،
        دوما تجربه ای متفاوت برای دختران جوان دانشجو که هنوز قدم در محیط های
        صنعتی نگذاشته بودند گشت و در انتها باعث کاهش در صرف زمان، هزینه های نگه
        داری تاسیسات کارخانه (آب، برق، گاز و...) و رفت و آمد پرسنل شده بود.
      </span>
    ),
  },
  {
    id: 2,
    title: "مصاحبه خبری",
    image: NEWSINTERVIEWIMG,
    carouselImage: [{ original: NEWSINTERVIEWIMG }],
    video: NEWSINTERVIEWMP4,
    body: (
      <span>
        با تاسیس کارگاه خانگی و تامین منابع مالی با چرخه تولید بدون دریافت هیچ
        گونه وامی ستادخبری صدا و سیما جهت نشر این خبر و معرفی یک کارآفرین خانم
        جوان در حوضه برق و الکترونیک در تاریخ 1400/10/19 به کارگاه خانگی که یکی
        از کارگاه های صنایع بیت الکترونیک شمال بوده است حضور پیدا کرده و مصاحبه
        ای حول محورهای اشتغال بانوان، موانع موجود بر سر راه تولید کنندگان، بحث
        چگونه می توان کارآفرین بود و... با مدیر عامل مجموعه خانم شهربانو ملک دار
        داشته اند که ایشان در توضیحات خود به این امر اشاره نمودند که کارآفرینی
        در درجه اول به تقویت مهارت‌ها و تخصص فردی و سپس پیوند جهت دار بین
        دانشگاه‌ها و صنایع نیاز دارد. وی در مصاحبه خبری خود استخدام دانشجویان
        خلاق و متخصص در کادر فنی خود و هم چنین افراد تحت پوشش بهزیستی و کمیته
        امداد جهت تکمیل کادر پرسنل خود را جزیی از برنامه های این مجموعه دانست.
        ایشان همچنین دستگاه‌ها و تکنولوژی های خود را معرفی و برای گسترش فعالیت
        های خود مدل های جدید و ایده های نویی را ارائه دادند.
      </span>
    ),
  },
];
